















































  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { PaymentChannelModel, ProductSummaryModel } from '@/store/product/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Model, Prop, Provide } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'paymentOptions',
    components: {},
    mixins: [],
  })
  export default class paymentOptions extends Vue {
    @Action('GetPaymentOption', { namespace: 'product' }) GetPaymentOption!: () => Promise<ServerResponse>;
    @Action('BuyProduct', { namespace: 'product' }) BuyProduct!: (params: { trx_id: string; form: FormData }) => Promise<ServerResponse>;

    @State('payment_optons', { namespace: 'product' }) payment_optons: PaymentChannelModel[];

    @Prop() summary!: { label?: string; value: any; label_class?: string; value_class?: string; row_class?: string; label_colspan?: string; value_colspan?: string; show_label?: boolean; html_value?: boolean }[];
    @Prop() transaction_id!: string;

    @Model('input', { type: Boolean }) showModal!: boolean;

    public selectedPayment = '';
    public onLoad = false
    // public productSummary:{label: string, value: any, is_currency: false}[] = []

    public created(): void {}

    public mounted(): void {
      this.GetPaymentOption();
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      this.onLoad = true
      if (this.transaction_id) {
        this.BuyProduct({ trx_id: this.transaction_id, form: new FormData(ev.target as HTMLFormElement) })
          .then((res) => {
            this.$router.push({ name: 'transaction-history' });
            this.$emit('OnSuccess', res);
          })
          .catch((reason) => {
            this.$emit('OnError', reason);
          })
          .finally(() => {
            this.onLoad = false
            this.$emit('input', false);
          });
      } else Toaster.Error('transaksi tidak di temukan!', { title: 'Gagal' });
      this.onLoad = false
    }

    /**
     * OnCancel
     */
    public OnCancel() {
      this.$bvModal
        .msgBoxConfirm('apakah anda yakin?', {
          title: 'Batalkan Pembayaran?',
          centered: true,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          cancelVariant: 'success',
          titleTag: 'h6',
          headerBgVariant: 'warning',
          headerClass: 'p-2 text-white',
          footerClass: 'd-flex justify-content-between border-0 p-2',
          bodyClass: 'text-center bold pb-0',
          okTitle: 'Ya',
          cancelTitle: 'kembali',
        })
        .then((ok) => {
          if (ok) {
            this.$emit('input', false);
          }
        });
    }
  }
